import React, { Fragment, useEffect, useState } from 'react'

import { tw } from 'workfront-twind'

export function PerformanceInfo() {
  const [currentTimeout, setCurrentTimeout] = useState()
  const marks = window.performance.getEntriesByType('mark')
  const measures = window.performance.getEntriesByType('measure')
  const {
    connectEnd,
    connectStart,
    domComplete,
    domContentLoadedEventEnd,
    domInteractive,
    domainLookupEnd,
    domainLookupStart,
    loadEventEnd,
    loadEventStart,
    requestStart,
    responseEnd,
    responseStart,
    secureConnectionStart,
    startTime,
    unloadEventEnd,
    unloadEventStart,
  } = performance.getEntriesByType('navigation')[0]

  useEffect(() => {
    let timeouts = [0, 25, 100, 1000, 2000, 3000]
    let timeout
    ;(function checkForWindowPerfMetrics() {
      if (timeouts.length) {
        const nextTimeout = timeouts.shift()
        setCurrentTimeout(nextTimeout)
        timeout = setTimeout(checkForWindowPerfMetrics, nextTimeout)
      }
    })()

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div
      className={tw`m-auto w-1/3 pt-6 perf-slotted-content`}
      key={currentTimeout}
    >
      <h1 className={tw`h1`}>Frontend Performance</h1>
      <div>TEST FOR MFE DEP CHECK</div>
      {marks ? (
        <>
          <PerformanceGroupHeader label="Performance Marks" />
          <PerformanceGroupData
            data={marks.map(({ name, startTime }) => ({
              name,
              timing: startTime,
            }))}
          />
        </>
      ) : null}
      {measures ? (
        <>
          <PerformanceGroupHeader label="Performance Measures (duration)" />
          <PerformanceGroupData
            data={measures.map(({ name, duration }) => ({
              name,
              timing: duration,
            }))}
          />
        </>
      ) : null}
      <PerformanceGroupHeader label="Performance Navigation" />
      <PerformanceGroupData
        data={[
          {
            name: 'Time to first byte',
            timing: responseStart - startTime,
          },
          {
            name: 'Time to last byte',
            timing: responseEnd - startTime,
          },
          {
            name: 'Reponse transfer time',
            timing: responseEnd - responseStart,
          },
          {
            name: 'Time to response',
            timing: responseStart - requestStart,
          },
          {
            name: 'Request Response',
            timing: responseEnd - requestStart,
          },
          {
            name: 'DNS',
            timing: domainLookupEnd - domainLookupStart,
          },
          {
            name: 'TCP',
            timing: connectEnd - connectStart,
          },
          secureConnectionStart && {
            name: 'SSL',
            timing: requestStart - secureConnectionStart,
          },
          { name: 'Page Load', timing: loadEventStart - startTime },
        ].filter(Boolean)}
      />
      <PerformanceGroupHeader label="Paint" />
      <PerformanceGroupData
        data={performance
          .getEntriesByType('paint')
          .map(({ name, startTime }) => ({ name, timing: startTime }))}
      />
      <PerformanceGroupSubHeader label="DOM" />
      <PerformanceGroupData
        data={[
          { name: 'Content Loaded', timing: domContentLoadedEventEnd },
          { name: 'Complete', timing: domComplete },
          { name: 'Interactive', timing: domInteractive },
        ]}
      />
      <PerformanceGroupSubHeader label="Document" />
      <PerformanceGroupData
        data={[
          { name: 'Load', timing: loadEventEnd },
          {
            name: 'Unload',
            timing: unloadEventEnd - unloadEventStart,
          },
        ]}
      />
    </div>
  )
}

function PerformanceGroupHeader({ label }) {
  return <div className={tw`h2 border-solid border-b`}>{label}</div>
}

function PerformanceGroupSubHeader({ label }) {
  return <div className={tw`h3 py-2`}>{label}</div>
}

function PerformanceGroupData({ data }) {
  return (
    <div className={tw`flex flex-wrap overflow-hidden pt-2`}>
      {data.map(({ name, timing }) => (
        <PerformanceEntry key={name} name={name} timing={timing} />
      ))}
    </div>
  )
}

const formatTime = (ms) => `${Math.ceil(ms)}ms`

function PerformanceEntry({ name, timing }) {
  return (
    <>
      <div className={tw`w-2/3 pb-1 overflow-hidden font-semibold`}>
        {name}:
      </div>
      <div className={tw`w-1/3 pb-1 overflow-hidden text-right`}>
        {formatTime(timing)}
      </div>
    </>
  )
}
